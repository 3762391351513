#root {
    height: 100%;

    .base-page_modified-ant-layout {
        height: 100%;
        background: #fff;

        /* Header */
        .ant-layout-header {
            display: flex;
            justify-content: space-between;
            background: #fff;
            border-bottom: 1px solid #D8E1EC;

            .logo {
                width: 120px;
                height: 31px;
                margin: 16px 14px 16px 0;
              }

              /* Divider */
            .modified-divider {
                height: 44px !important;
            }

            @media (max-width: 576px) { 
                .header-left-col {
                    width: 100%;

                    .modified-divider {
                        display: none;
                    }
                }

                .header-right-col {
                    display: none;
                }
             }
        }

        /* Content */
        .ant-layout-content {
            height: 100%;

            /* Card */
            .ant-card-head-title {
                font-weight: bold;
                font-size: 24px;
            }
            /* Button */
            button {
                width: 100%;
            }
        }

        /* Footer */
        .ant-layout-footer {
            background: #fff;

            /* Menu */
            .ant-menu {
                text-align: center;
            }
            .ant-menu-horizontal {
                border-bottom: none;
            }
        }
    }

    .main_modified-ant-layout {
        min-height: 100vh !important;
        background: #fff;

        /* Header */
        .ant-layout-header {
            width: 100%;
            display: flex;
            border-bottom: 1px solid #D8E1EC;
            position: fixed;
            z-index: 99;

            .logo {
                width: 120px;
                height: 31px;
                margin: 16px 14px 16px 0;
              }

              /* Divider */
            .modified-divider {
                height: 44px !important;
            }

            /* Menu */
            .ant-menu {
                text-align: center;
            }
            .ant-menu-horizontal {
                border-bottom: none;
            }
            .modified-item { 
                border-bottom: 2px solid transparent !important; 
            }
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                border: none !important;
                outline: none !important;
            }

            /* Icon */
            .global-menu-dropdown-icon {
                display: block !important;
                font-size: 12px;
                color: #ccc !important;
                margin-top: 2px;
            }
        }

        /* Content */
        .ant-layout-content {

            .site-layout {
                padding: 30px;
                margin: 0 4% 4%;
                border: 1px solid #E4E9F0;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                background: #f0f2f5;
            }

            /* Card */
            .ant-card-head-title {
                font-weight: bold;
                font-size: 24px;
            }
            .ant-card-body {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            /* Select */
            .ant-select {
                width: 100%;
            }
            .ant-select-multiple .ant-select-selector {
                background-color: #f0f2f5 !important;
                border: 1px solid #8a8a8a !important;
                border-radius: 5px !important;
            }
        }
    }
}

/* Loader */
.loader {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}